import styled from "framework/StyledComponents";

export const PostContent = styled.div`
    border-top: 0.2em solid ${props => props.theme.sidebar};
    padding-top: 1.5em;
`;

export const PostExcerpt = styled.p`
    margin: 0 0 1.5em;
`;
